import MainTitle from '../../components/MainTitle'
import useAuth from '../../hooks/useAuth'
import { useState } from 'react'
import ReportConfirm from '../../components/ReportConfirm'
import Modal from '../../components/Modal'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
function Reports() {
  const [showReportConfirmation, setShowReportConfirmation] = useState(false)
  const [sessionsReportData, setSessionsReportData] = useState({})
  const [modalTitle, setModalTitle] = useState('')
  const [showModal, setShowModal] = useState(false)
  const axios = useAxiosPrivate()

  const SessionsReportModal = () => {
    const sessionReport = async (e) => {
      e.preventDefault()
      resetModal()
      setShowReportConfirmation(true)
      const data = {
        start_date: sessionsReportData.startDate,
        end_date: sessionsReportData.endDate,
      }
      try {
        const response = await axios.post(`/reports/sessions`, data)
      } catch (error) {}
    }

    return (
      <form onSubmit={sessionReport}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Report Start Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              name="start_date"
              id="name"
              onChange={(e) => (sessionsReportData.startDate = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Report End Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              name="end_date"
              id="name"
              onChange={(e) => (sessionsReportData.endDate = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <span>
            Note: If you don't select any dates the report will pull all of the
            data available
          </span>
        </div>

        <div className="mb-3 flex justify-between">
          <button
            type="button"
            className="btn red mt-4 w-1/3"
            onClick={resetModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn mt-4 w-1/3">
            Download
          </button>
        </div>
      </form>
    )
  }
  const highMediumClientRisk = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/clientsRisk`)
    } catch (error) {
      console.log(error)
    }
  }

  const noRiskClients = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/noClientRisk`)
    } catch (error) {
      console.log(error)
    }
  }

  const noFutureBookings = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/noFutureBookings`)
    } catch (error) {
      console.log(error)
    }
  }

  const practitionerCancellationsReport = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/practitioner-cancellations`)
    } catch (error) {
      console.log(error)
    }
  }
  
  const coreQuestionnaireReport = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/core-questionnaire`)
    } catch (error) {
      console.log(error)
    }
  }
  
  const queryLogReport = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/query-log`)
    } catch (error) {
      console.log(error)
    }
  }

  const completedClients = async () => {
    setShowReportConfirmation(true)
    try {
      await axios.get(`/reports/completedClients`)
    } catch (error) {
      console.log(error)
    }
  }

  const sessionsReportClicked = () => {
    setSessionsReportData({})
    setModalTitle(`Sessions Report`)
    setShowModal(true)
  }

  const resetModal = () => {
    setSessionsReportData({})
    setShowModal(false)
  }

  const ModalBody = () => {
    if (sessionsReportData) {
      return <SessionsReportModal />
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {showReportConfirmation && (
        <ReportConfirm show={setShowReportConfirmation} />
      )}
      <MainTitle title="Reports" />
      <article className="">
        <div className="grid grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              Sessions breakdown report
            </div>
            <div className="flex justify-center">
              <button
                className="btn green mr-4"
                onClick={sessionsReportClicked}
              >
                Download
              </button>
            </div>
          </div>

          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              Medium & High risk clients report
            </div>
            <div className="flex justify-center">
              <button className="btn green mr-4" onClick={highMediumClientRisk}>
                Download
              </button>
            </div>
          </div>

          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              Completed Clients
            </div>
            <div className="flex justify-center">
              <button className="btn green mr-4" onClick={completedClients}>
                Download
              </button>
            </div>
          </div>

          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              No Future Bookings Report
            </div>
            <div className="flex justify-center">
              <button className="btn green mr-4" onClick={noFutureBookings}>
                Download
              </button>
            </div>
          </div>

          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              Practitioner Cancellations
            </div>
            <div className="flex justify-center">
              <button className="btn green mr-4" onClick={practitionerCancellationsReport}>
                Download
              </button>
            </div>
          </div>
          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
            CORE Questionnaire
            </div>
            <div className="flex justify-center">
              <button className="btn green mr-4" onClick={coreQuestionnaireReport}>
              Download
              </button>
            </div>
          </div>
          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              Query Logs
            </div>
            <div className="flex justify-center">
              <button className="btn green mr-4" onClick={queryLogReport}>
                Download
              </button>
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}

export default Reports
