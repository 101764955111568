/* eslint-disable */
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faEye,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import MainTitle from '../../components/MainTitle'
import { useParams, useLocation} from 'react-router-dom'
import SlideOut from '../../components/SlideOut'
// import './drop-file-input.css'

const Audits = () => {
  //Auth
  const { clientId, audit, type } = useParams()
  const { setShowLoader } = useAuth()
  const axios = useAxiosPrivate()
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRecords, setCurrentRecords] = useState([])
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [audits, setAudits] = useState([])
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  const [claimData, setClaimData] = useState({
    reference: '',
    external_reference: '',
  })

  useEffect(() => {
    setClientData(location.state)
    getClaimById()
    getAudits()
  }, [clientId])

  const getClaimById = async () => {
    try {
      const response = await axios.get(`/clients/${clientId}/claims/${audit}`)
      await setClaimData(response.data.result)
    } catch (error) {}
  }

  const getAudits = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/${audit}/audits/${type}`)
    
      if (response.data.result) {
        await setAudits(response.data.result)
        await setFilteredRecords(response.data.result)
        await setCurrentRecords(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = audits.filter((e) => {
        return (
          e.auditable_type.toLowerCase().match(searchString) ||
          e.event.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(audits)
      setCurrentRecords(audits.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(audits.length / recordsPerPage))
    }
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const safeJsonParse = (jsonString) => {
    if (!jsonString) return {}
    try {
      return JSON.parse(jsonString)
    } catch (error) {
      console.error('Invalid JSON string:', error)
      return {}
    }
  }

  const formatKey = (key) => {
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  
  const formatJson = (jsonString, limit = null) => {
    const jsonObj = safeJsonParse(jsonString)
    const entries = Object.entries(jsonObj)
    const displayedEntries = limit ? entries.slice(0, limit) : entries;
  
    return (
      <div>
        {displayedEntries.map(([key, value]) => (
          <div key={key} className="break-words">{`${formatKey(key)}: ${value}`}</div>
        ))}
        {limit && entries.length > limit && (
          <div className="break-words"></div>
        )}
      </div>
    )
  }

  const getEventClassName = (event) => {
    switch (event) {
      case 'created':
        return 'bg-green-400 text-green-800'
      case 'updated':
        return 'bg-blue-400 text-blue-800'
      case 'deleted':
        return 'bg-red-400 text-red-800'
      default:
        return ''
    }
  }

  const handleClick = (audit) => {
    setOpen(true);
    setSelectedAudit(audit);
  }

  return (
    <section>
      <MainTitle
        title={`Client: ${clientData?.name} (${clientData?.reference})`}
        client={clientData}
        backButton
        child={clientData.child}
        discharge
        waiting
      />

      <ClientNav active="audits" client={clientData} type={type}/>

      <section>
          <h2 className="font-bold text-lg ">
           {type} Audit Log
          </h2>
          <p className='mb-4 text-sm'> {type === 'Client' && (<> ({clientData?.reference})</>)} {type === 'Claim' && (<>{claimData.reference} ({claimData.external_reference})</>)} </p>
          <div>
            <div className="pb-4 flex justify-between">
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    onClick={handleSearch}
                  />
                </div>
                <input
                  type="text"
                  id="table-search"
                  onChange={handleSearch}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Audits"
                />
              </div>
            </div>

            <table className="table-main">
              <thead>
                <tr>
                  <th scope="col">User</th>
                  <th scope="col">Status</th>
                  <th scope="col">Timestamp</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.length > 0 ? (
                  currentRecords?.map((audit) => {
                    return (
                      <tr key={audit.id}>
                        <td>{audit.user_name}</td>
                        <td>
                          <div className={`status ${getEventClassName(audit.event)} capitalize`}>
                            {audit.event}
                          </div>
                        </td>
                        <td>
                          {moment(audit.updated_at).format('DD/MM/YYYY hh:mma')}
                        </td>
                        <td className='cursor-pointer flex justify-center' title="View Audits" onClick={() => handleClick(audit)}>
                          <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 hover:bg-blue-500 cursor-pointer">
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <p className="no-records">No audits found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              next={nextPage}
              prev={previousPage}
              first={indexOfFirstRecord}
              last={indexOfLastRecord}
              total={filteredRecords.length}
            />
          </div>
      </section>

    <SlideOut open={open} setOpen={setOpen} audit={selectedAudit} />

    </section>
  )
}

export default Audits
